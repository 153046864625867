import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from "firebase";
const firebaseConfig = {
  apiKey: "AIzaSyDj22nk3SgyRFhRjeeGKFx6skyJcH7umlc",
  authDomain: "kargonerdenet.firebaseapp.com",
  projectId: "kargonerdenet",
  storageBucket: "kargonerdenet.appspot.com",
  messagingSenderId: "743515271707",
  appId: "1:743515271707:web:9aa953cc959f41bf0bbebe",
  measurementId: "G-0K5MB79D7D"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
