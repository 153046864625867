import logo from './logo.png';
import './App.css';
import React,{Component} from "react";
import firebase from "firebase";
import axios from "axios";
class App extends Component {

    state = {
        userUID:null
    };
    barcodeRef = React.createRef();
    constructor(props) {
        super(props);
        window.onSubmit = this.onSubmit;
    }

    componentDidMount() {
        this.auth().then().catch();
    }


    auth = async () =>{
        firebase.auth().onAuthStateChanged((user)=>{
            if(user === null){
                firebase.auth().signInAnonymously();
            }else{
                this.setState({
                    userUID:user.uid
                },()=>{
                    firebase.firestore().collection("users").doc(user.uid).get().then((userData)=>{

                        if(userData.exists){
                            firebase.firestore().collection("users").doc(user.uid).update({
                                last_login:new Date().toLocaleString("tr")
                            })
                        }else{
                            firebase.firestore().collection("users").doc(user.uid).set({
                                last_login:new Date().toLocaleString("tr")
                            })
                        }

                    }).catch((err)=>{
                        console.log(err)
                    })
                })
            }
        })
    }

    onSubmit = (token) =>{

        if(this.barcodeRef.current.value && this.barcodeRef.current.value.trim().length>0 && token && token.trim().length>0){
            let obj = {
                cargo_code:this.barcodeRef.current.value,
                recaptcha_code:token,
                user_uuid:this.state.userUID
            };
            axios.post("https://kargonerde.net/general/getCargoResult",JSON.stringify({params:obj}),{
                headers:{
                    "content-type":"application/json"
                }
            }).then((res)=>{
                console.log(res);
            }).catch((err)=>{
                console.log(err);
            })
        }
    }

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <img src={logo} alt="logo" />
                    <p>
                        Kargo <code>NERDE!</code>
                    </p>
                    <input ref={this.barcodeRef} type="text" className={"search-input"} autoCapitalize="off" autoComplete="off"
                           autoCorrect="off" autoFocus="" spellCheck="false" title="Ara" aria-label="Ara"
                    />

                    <button className={"g-recaptcha search-btn"}
                            data-sitekey="6Lf3URIaAAAAAA7xUtB4TTQh8e8UMoikWuDEqIFc"
                            data-callback='onSubmit'
                            data-action='submit'>Ara</button>
                </header>
            </div>
        );
    }
}



export default App;
